<template>
  <div class="csn-game-info" v-if="gameInfo">
    <section class="csn-game-info-section-1">
      <div class="csn-game-info-example">
        <img
          v-if="gameInfo.image"
          class="csn-game-info-image"
          :src="gameInfo.image"
          alt="game image"
        />
        <img
          v-else-if="isCasinoPlatin"
          src="@/assets/images/default_gamebox_horizontal.png"
        />
        <img
          v-else
          src="https://cdn-casino.blackpage.pl/images/logo/casino-five.svg"
        />
      </div>
      <div class="csn-game-info-short-data">
        <div class="csn-game-info-short-data-header">
          <span class="csn-game-info-short-data-title">
            {{ gameInfo.name }}
          </span>
          <Rating
            :isRatingPending="isRatingPending"
            :value="rating"
            :isEditable="isLogged"
            :changeValue="changeRatingValue"
          />
        </div>
        <div class="csn-game-info-vendor-logo">
          <img :src="gameInfo.vendorLogo" alt="vendor logo" />
        </div>
        <div class="csn-game-info-short-data-btn-container">
          <Route
            class="casino-btn casino-btn-default csn-game-info-short-data-free-play-btn"
            v-if="gameInfo.buttons.demo"
            :to="{
              name: pathName.GAME_DEMO,
              params: { slug: this.slug },
            }"
          >
            {{ t('free_play') }}
          </Route>
          <Route
            class="casino-btn casino-btn-pink csn-game-info-short-data-real-play-btn"
            v-if="gameInfo.buttons.play"
            :to="{
              name: pathName.GAME_PLAY,
              params: { slug: this.slug },
            }"
          >
            {{ t('play_for_real') }}
          </Route>
        </div>
        <div
          class="csn-game-info-short-data-description"
          v-html="gameInfo.description.short"
        ></div>
      </div>
    </section>
    <section class="csn-game-info-breadcrumbs">
      <div>
        <Route :to="{ name: pathName.HOME }"><span>Online Casino</span></Route>
        <span>/</span>
        <Route
          :to="{
            name: pathName.GAME_CATEGORY,
            params: { slug: gameInfo.category.urlSlug },
          }"
        >
          <span>{{ gameInfo.category.name }}</span>
        </Route>
        <span>/</span>
        <span>{{ gameInfo.name }}</span>
      </div>
    </section>
    <section class="csn-game-info-section-2">
      <div
        class="csn-game-info-description"
        v-html="gameInfo.description.full"
      ></div>
    </section>
    <section class="csn-game-info-section-3">
      <div class="csn-game-info-card-container">
        <div
          class="csn-game-info-card"
          v-for="(item, index) in gameInfo.description.cards"
          :key="index"
        >
          <div class="csn-game-info-card-image-container">
            <img
              class="csn-game-info-image"
              :src="item.image"
              alt="game image"
            />
          </div>
          <span class="csn-game-info-card-title">
            {{ item.name }}
          </span>
          <span class="csn-game-info-card-description">
            {{ item.description }}
          </span>
        </div>
      </div>
    </section>
    <section class="csn-game-info-section-4">
      <div class="csn-game-info-footer-title">
        {{ gameInfo.description.footer.title }}
      </div>
      <div class="csn-game-info-footer-subtitle">
        {{ gameInfo.description.footer.titleAdditional }}
      </div>
      <div
        class="csn-game-info-footer-description"
        v-html="gameInfo.description.footer.description"
      ></div>
      <div class="csn-game-info-footer-button-container">
        <Route
          class="casino-btn casino-btn-pink csn-game-info-footer-button"
          v-if="gameInfo.buttons.play"
          :to="{
            name: pathName.GAME_PLAY,
            params: { slug: this.slug },
          }"
        >
          {{ t('start_playing') }}
        </Route>
      </div>
    </section>
    <section class="csn-game-info-section-5" :ref="ref">
      <HorizontalList
        title="Other Top Games"
        :getList="getTopGameList"
        :itemsPerScreen="itemsPerScreen"
      />
    </section>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { GameApi, AuthApi } from '@/api'
import {
  GAME_INFO,
  Module,
  GAME_INFO_BY_SLUG,
  CURRENT_LOCATION,
  EMPTY_STRING,
  SET_IS_PENDING,
  UPDATE_GAME_INFO_DICITONARY,
  RouteName,
  Digit,
  IS_LOGGED,
  ResponseState,
  CASINO_PLATIN,
} from '@/constants'
import {
  getGetter,
  dest,
  commitMutation,
  getVisibleGameCardNumber,
} from '@/helpers'
import { cardsPerScreenCalculationMixin } from '@/mixins'

export default {
  name: GAME_INFO,
  mixins: [cardsPerScreenCalculationMixin],
  components: {
    HorizontalList: () => import('@/components/HorizontalList'),
    Rating: () => import('@/components/Rating'),
    Route: () => import('@/components/Route'),
  },
  data: () => ({
    slug: EMPTY_STRING,
    rating: Digit.NOUGHT,
    isRatingPending: false,
  }),
  computed: {
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
    ...mapGetters({ isLogged: dest([Module.USER, IS_LOGGED]) }),
    itemsPerScreen() {
      return getVisibleGameCardNumber(this.containerWidth)
    },
    gameInfo() {
      return getGetter([Module.GAME_INFO, GAME_INFO_BY_SLUG])(this.slug)
    },
    pathName: () => ({
      GAME_CATEGORY: RouteName.GAME_CATEGORY,
      GAME_DEMO: RouteName.GAME_DEMO,
      GAME_PLAY: RouteName.GAME_PLAY,
      HOME: RouteName.HOME,
    }),
    t() {
      return this.$createComponentTranslator(GAME_INFO)
    },
    isCasinoPlatin: () => process.env.VUE_APP_THEME === CASINO_PLATIN,
  },
  methods: {
    ...mapActions(Module.GAME_INFO, [UPDATE_GAME_INFO_DICITONARY]),
    async changeRatingValue(value) {
      this.isRatingPending = true
      const { state } = await AuthApi.postGameRating({
        form: {
          rating: String(value),
          game: String(this.gameInfo.id),
        },
      })

      if (state === ResponseState.OK) {
        this.rating = value
      }
      this.isRatingPending = false
    },
    async getGameRating() {
      this.isRatingPending = true
      const response = await GameApi.getGameRating({
        id: this.gameInfo.id,
      })

      if (response?.data?.rating) {
        this.rating = response.data.rating
      }
      this.isRatingPending = false
    },
    getTopGameList: GameApi.getTopGameList,
  },
  watch: {
    gameInfo: {
      immediate: true,
      handler(gameInfo) {
        if (!gameInfo?.id) {
          return
        }
        this.getGameRating()
      },
    },
    isLogged() {
      if (!this.gameInfo.id) {
        return
      }
      this.getGameRating()
    },
    CURRENT_LOCATION: {
      deep: true,
      immediate: true,
      handler(value) {
        this.slug = value?.params?.slug
      },
    },
  },
  async beforeRouteUpdate(to, from, next) {
    commitMutation([Module.MAIN, SET_IS_PENDING], true)
    await this.UPDATE_GAME_INFO_DICITONARY({ urlSlug: to.params.slug })
    commitMutation([Module.MAIN, SET_IS_PENDING], false)
    next()
  },
}
</script>
