var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.gameInfo)?_c('div',{staticClass:"csn-game-info"},[_c('section',{staticClass:"csn-game-info-section-1"},[_c('div',{staticClass:"csn-game-info-example"},[(_vm.gameInfo.image)?_c('img',{staticClass:"csn-game-info-image",attrs:{"src":_vm.gameInfo.image,"alt":"game image"}}):(_vm.isCasinoPlatin)?_c('img',{attrs:{"src":require("@/assets/images/default_gamebox_horizontal.png")}}):_c('img',{attrs:{"src":"https://cdn-casino.blackpage.pl/images/logo/casino-five.svg"}})]),_c('div',{staticClass:"csn-game-info-short-data"},[_c('div',{staticClass:"csn-game-info-short-data-header"},[_c('span',{staticClass:"csn-game-info-short-data-title"},[_vm._v(" "+_vm._s(_vm.gameInfo.name)+" ")]),_c('Rating',{attrs:{"isRatingPending":_vm.isRatingPending,"value":_vm.rating,"isEditable":_vm.isLogged,"changeValue":_vm.changeRatingValue}})],1),_c('div',{staticClass:"csn-game-info-vendor-logo"},[_c('img',{attrs:{"src":_vm.gameInfo.vendorLogo,"alt":"vendor logo"}})]),_c('div',{staticClass:"csn-game-info-short-data-btn-container"},[(_vm.gameInfo.buttons.demo)?_c('Route',{staticClass:"casino-btn casino-btn-default csn-game-info-short-data-free-play-btn",attrs:{"to":{
            name: _vm.pathName.GAME_DEMO,
            params: { slug: this.slug },
          }}},[_vm._v(" "+_vm._s(_vm.t('free_play'))+" ")]):_vm._e(),(_vm.gameInfo.buttons.play)?_c('Route',{staticClass:"casino-btn casino-btn-pink csn-game-info-short-data-real-play-btn",attrs:{"to":{
            name: _vm.pathName.GAME_PLAY,
            params: { slug: this.slug },
          }}},[_vm._v(" "+_vm._s(_vm.t('play_for_real'))+" ")]):_vm._e()],1),_c('div',{staticClass:"csn-game-info-short-data-description",domProps:{"innerHTML":_vm._s(_vm.gameInfo.description.short)}})])]),_c('section',{staticClass:"csn-game-info-breadcrumbs"},[_c('div',[_c('Route',{attrs:{"to":{ name: _vm.pathName.HOME }}},[_c('span',[_vm._v("Online Casino")])]),_c('span',[_vm._v("/")]),_c('Route',{attrs:{"to":{
          name: _vm.pathName.GAME_CATEGORY,
          params: { slug: _vm.gameInfo.category.urlSlug },
        }}},[_c('span',[_vm._v(_vm._s(_vm.gameInfo.category.name))])]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.gameInfo.name))])],1)]),_c('section',{staticClass:"csn-game-info-section-2"},[_c('div',{staticClass:"csn-game-info-description",domProps:{"innerHTML":_vm._s(_vm.gameInfo.description.full)}})]),_c('section',{staticClass:"csn-game-info-section-3"},[_c('div',{staticClass:"csn-game-info-card-container"},_vm._l((_vm.gameInfo.description.cards),function(item,index){return _c('div',{key:index,staticClass:"csn-game-info-card"},[_c('div',{staticClass:"csn-game-info-card-image-container"},[_c('img',{staticClass:"csn-game-info-image",attrs:{"src":item.image,"alt":"game image"}})]),_c('span',{staticClass:"csn-game-info-card-title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"csn-game-info-card-description"},[_vm._v(" "+_vm._s(item.description)+" ")])])}),0)]),_c('section',{staticClass:"csn-game-info-section-4"},[_c('div',{staticClass:"csn-game-info-footer-title"},[_vm._v(" "+_vm._s(_vm.gameInfo.description.footer.title)+" ")]),_c('div',{staticClass:"csn-game-info-footer-subtitle"},[_vm._v(" "+_vm._s(_vm.gameInfo.description.footer.titleAdditional)+" ")]),_c('div',{staticClass:"csn-game-info-footer-description",domProps:{"innerHTML":_vm._s(_vm.gameInfo.description.footer.description)}}),_c('div',{staticClass:"csn-game-info-footer-button-container"},[(_vm.gameInfo.buttons.play)?_c('Route',{staticClass:"casino-btn casino-btn-pink csn-game-info-footer-button",attrs:{"to":{
          name: _vm.pathName.GAME_PLAY,
          params: { slug: this.slug },
        }}},[_vm._v(" "+_vm._s(_vm.t('start_playing'))+" ")]):_vm._e()],1)]),_c('section',{ref:_vm.ref,staticClass:"csn-game-info-section-5"},[_c('HorizontalList',{attrs:{"title":"Other Top Games","getList":_vm.getTopGameList,"itemsPerScreen":_vm.itemsPerScreen}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }